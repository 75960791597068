import React, { useEffect, useRef } from "react"
import PageLayout from "../components/layout"
import Review from "../../../components/review"

import SEO from "../components/seo"
import Page from "../data/page"
import PokerReview from "../data/poker"
import CasinoReview from "../data/casino"

import logo from "../images/logo.png"

const IndexPage = () => {
  const casino = useRef(null)
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true)
  })
  const handleScroll = () => {
    if (window.scrollY > casino.current.offsetTop - window.innerHeight + 100) {
      casino.current.style.backgroundColor = "black"
    } else {
      casino.current.style.backgroundColor = "white"
    }
  }
  return (
    <PageLayout logo={logo} title="PokerSG">
      <SEO title="Home" />
      <div className="" id="home"></div>
      <div className="about">
        <div className="poker-review">
          <h3>{Page.title}</h3>
          <p>{Page.description}</p>
          <h4>{Page.subtopic}</h4>
          <p>{Page.description_2}</p>
        </div>
      </div>
      <div id="poker-review" className="poker-review">
        <h3 style={{ marginBottom: "10px" }}>{Page.review_1}</h3>
        <p>{Page.review_2}</p>
        <h2>
          <span role="img" aria-label="card">
            🃏
          </span>
          &nbsp;{Page.poker_review}
        </h2>
        {PokerReview.map((review, index) => (
          <Review review={review} key={index} />
        ))}
      </div>
      <div className="review-list" ref={casino}>
        <div id="casino-review" className="casino-review">
          <h2>
            <span role="img" aria-label="slot">
              🎰
            </span>
            &nbsp;{Page.casino_review}
          </h2>
          {CasinoReview.map((review, index) => (
            <Review review={review} key={index} />
          ))}
        </div>
      </div>
    </PageLayout>
  )
}

export default IndexPage
