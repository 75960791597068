import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../../../components/layout"

const PageLayout = ({ logo, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          url
        }
      }
    }
  `)

  return (
    <Layout logo={logo} data={data}>
      {children}
    </Layout>
  )
}

export default PageLayout
