import React from "react"

const Review = ({ review, index }) => {
  return (
    <div
      key={index}
      className="review"
      style={{ maxWidth: `100%`, marginBottom: `1.45rem` }}
    >
      <div
        data-sal="zoom-in"
        data-sal-duration="800"
        data-sal-delay="0"
        data-sal-easing="ease"
      >
        <a href={review.link}>
          <img
            className="banner"
            src={review.banner}
            width="100%"
            alt={`${review.name} nammer`}
          />
        </a>
      </div>
      <div className="flex">
        <div className="brand">
          <img className="logo" src={review.logo} alt={`${review.name} logo`} />
          <div className="rating">
            <p>{review.rating}</p>
            Out of 5
          </div>
        </div>
        <div className="info">
          <h3 className="title">
            <a href={review.link}>
              {review.name}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#0070c9"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <g fill="none" fillRule="evenodd">
                  <path d="M18 14v5a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8c0-1.1.9-2 2-2h5M15 3h6v6M10 14L20.2 3.8" />
                </g>
              </svg>
            </a>
          </h3>
          <p>{review.description}</p>
          <div className="offers">
            <p>{review.name} offers:</p>
            <ul>
              {review.offers.map((offer, index) => {
                return (
                  <li key={index}>
                    <p>{offer}</p>
                  </li>
                )
              })}
            </ul>
          </div>
          {review.note && <i>{review.note}</i>}

          <a className="cta" href={review.link}>
            Visit {review.name}
          </a>
        </div>
      </div>
    </div>
  )
}

export default Review
