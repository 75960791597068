import Natural8Banner from "../images/poker/natural8-banner.png"
import Natural8Logo from "../images/poker/n8-logo.png"

import Yes8Banner from "../images/poker/yes8-banner.jpg"
import Yes8Logo from "../images/poker/yes8-logo.jpg"

import AcebetBanner from "../images/poker/acebet-banner.jpg"
import AcebetLogo from "../images/poker/acebet-logo.jpg"

import HFiveBanner from "../images/poker/hfive-banner.jpg"
import HFiveLogo from "../images/poker/hfive-logo.png"

const CasinoReview = [
  {
    name: "Yes8",
    banner: Yes8Banner,
    logo: Yes8Logo,
    description:
      "The casino site offers a variety of games ranging from sports, slots and live casinos. They also offer many kinds of promotions for players who are thinking of starting such as 5% daily bonus, welcome bonus and a variety of rebate bonuses.",
    offers: [
      "A variety of promotions for slots, live casino and sports",
      "Reliable games for Singaporean players",
      "Many deposit and withdrawal payment options",
      "Secure and safe gaming platform for Singaporeans",
      "A variety of games for players who're interested in slots, live casino or sports",
    ],
    note: "",
    link: "www.yes8sg.com",
    rating: "3.5",
  },
  {
    name: "acebet99",
    banner: AcebetBanner,
    logo: AcebetLogo,
    description:
      "Another great site for Singaporeans is acebet99. The casino site offers a variety of options such as online casino (baccarat, blackjack, roulette and slots) and sports via CMDBet, IBCBet, MAXBet and SBOBet. As they specifically cater to Singaporean players, their deposit and withdrawal options are via bank transfers such as DBS, POSB, OCBC and UOB.",
    offers: [
      "Bank transfer deposit and withdrawal options catered to Singaporeans",
      "Standard bonus for first time players",
      "Limited promotions",
      "Trusted and reliable",
    ],
    note: "",
    link: "https://acebet99.com",
    rating: "2.0",
  },
  {
    name: "hfive555",
    banner: HFiveBanner,
    logo: HFiveLogo,
    description:
      "This operator offers their services mainly for Malaysians and Singaporeans. Besides the standard games that all operators offer such as online casino (baccarat, blackjack, roulette and slots) and sports, hfive555 also offers lottery. Their deposit and withdrawal times and quick and efficient, where you will receive your funds within 5-15 minutes.",

    offers: [
      "Quick deposit and withdrawal processing times",
      "Standard bonus for players",
      "A variety of promotions offered for different games",
      "Secure and reputable",
      "Standard customer service support",
    ],
    note: "",
    link: "https://hfive555.com",
    rating: "4",
  },
  {
    name: "Natural8Casino",
    banner: Natural8Banner,
    logo: Natural8Logo,
    description:
      "As one of the pioneers in the poker industry, Natural8 has officially ventured out into the casino world, offering a huge variety of casino games such as baccarat, blackjack, roulette, slots and many more. We are happy that the brand has decided to officially casino where all players will have more games to play under the brand besides poker.",
    offers: [
      "A wide range of monthly promotions",
      "All sorts of casino games",
      "Excellent 24/7 customer support",
      "Secure, reputable and reliable",
      "A variety of  deposit and withdrawal options (20+ cryptocurrencies, Neteller, Skrill, Ecopayz, WeChat Pay, AliPay etc)",
      "Caters to both small and big time players",
    ],
    note: "",
    link: "http://n8casino.com",
    rating: "4.5",
  },
]

export default CasinoReview
