const Page = {
  title: "Gambling in Thailand",
  description:
    "Thailand does have limited legal gambling and even though it's illegal, it's not strict. What we've found out is that the most popular form of gambling is lottery. Other than that, all other forms of gambling are illegal, under the Thailand Gambling Act of 1935. This law prohibits all gambling not specifically authorised by law.",
  subtopic: "",
  description_2:
    "The government hasn’t been too eager to authorise it as of now. It’s forbidden to gamble online in Thailand, including playing online poker, but if the strict laws aren’t that effective in slowing down land based gambling, players residing in Thailand do still get to play privately. There are online poker and casino operators that are still offering their services to players residing in Thailand. To access and play on online gambling softwares, we strictly advise players to be careful and not to use a Thai credit card for deposits and withdrawals. There are many other options available, depending on their operators.",
  review_1:
    "The best online Gambling Operator for players residing in Thailand.",
  review_2:
    "Below are the list of online gambling operators that we think are great for players residing in Thailand.",
  poker_review: "Poker Review",
  casino_review: "Casino Review",
}

export default Page
