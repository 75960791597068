import Natural8Banner from "../images/poker/natural8-banner.png"
import Natural8Logo from "../images/poker/n8-logo.png"

import PPBanner from "../images/poker/pppoker-banner.jpg"
import PPLogo from "../images/poker/pppoker-logo.png"

import UPokerBanner from "../images/poker/upoker-banner.png"
import UPokerLogo from "../images/poker/upoker-logo.png"

import SiamPokerBanner from "../images/poker/siampoker-banner.jpg"
import SiamPokerLogo from "../images/poker/siampoker-logo.jpg"

import BodogBanner from "../images/poker/bodog-banner.png"
import BodogLogo from "../images/poker/bodog-logo.png"

const PokerReview = [
  {
    name: "Natural8",
    banner: Natural8Banner,
    logo: Natural8Logo,
    description:
      "One of the best online gambling websites we've came across is www.natural8.com where they offer a variety of deposit and withdrawal options for players such as Neteller, Skrill, Ecopayz and Cryptocurrencies. They offer an excellent games with many tournaments running every hour. The playing experience has been positive and they offer really great promotions monthly.",
    offers: [
      "All sorts of monthly promotions catered to both small and big time players",
      "Free tickets to exclusive tournaments",
      "Lobby games where players can select whichever tables they want to play",
      "Excellent 24/7 customer support",
      "Secure, trustworthy and reliable games",
      "Many safe deposit and withdrawal payment options",
      "Fast deposit and withdrawal times",
      "Tournaments every hour",
      "Games suitable for both big and small time players",
      "Games where you can play via desktop and mobile",
      "Great website with clear information on promotions, games etc",
      "Offers Smart HUD where players can quickly learn your opponent's tendencies on the spot, and use them to your strategic advantage.",
      "Detailed information about all their current daily, weekly, monthly promotions, with guides and tutorials on how to play for first time players who are looking to start playing",
    ],
    note:
      "Do note that Singaporean players can still play on Natural8.com as it is carefully regulated, but not by the Singapore government.",
    link: "https://www.natural8.com/",
    rating: "4.5",
  },
  {
    name: "Bodog",
    banner: BodogBanner,
    logo: BodogLogo,
    description:
      "Bodog has officially opened their doors to the Asian market as Bodog88. The Thai market and therefore players based in Thailand are officially included to play here. This is our number 1 choice for Thailand at the moment and for a reason: Games are GOOD! Bodog also accepts deposits via Thai Banks. And not just one, almost ALL of them! The following local Thai Banks are accepted: Bangkok Bank, Kasikorn Bank, Siam Commercial Bank, Krung Thai Bank, Krungsri Bank, UOB Bank.",
    offers: [
      "Recently upgraded software",
      "Ability to deposit using Bitcoin or a local Thai bank",
      "Hand grabber for HUD",
      "Great online support",
      "Multi-tabling limited to 4 tables",
    ],
    note: "",
    link: "https://www.bd275.com/",
    rating: "3.5",
  },
  {
    name: "SiamPoker",
    banner: SiamPokerBanner,
    logo: SiamPokerLogo,
    description:
      "SiamPoker is the largest Asian Poker Network. Most of the traffic comes from Indonesia and China. The network includes a large number of skins, each of which is aimed at players from different Asian countries, namely, Indonesia, China, Malaysia, Vietnam, Nepal, Thailand, Cambodia, South Korea and others.",
    offers: [
      "Deposit via local banks",
      "Hold'em games but no Pot Limit Omaha yet",
      "Great website with clear information",
      "Reliable and reputable",
      "On-the-go play via app for iOS and Android",
    ],
    note: "",
    link: "http://siampro88.com/",
    rating: "4.0",
  },
  {
    name: "PPPoker",
    banner: PPBanner,
    logo: PPLogo,
    description:
      "PPPoker is another mobile app poker platform that has been growing in the online poker industry. Being only a poker software, not an operator itself, in order to play in the poker room, players can only make deposits and withdrawals via agents. PPPoker allows players to download and play using iOS, Android and PC. The market PPPoker targets are mainly amateur players from Asia. Traffic is growing fast on PPPoker this days attracting lots of new players from Thailand, Macau and Singapore. Real money games on PPPoker are hosted on private clubs. Multi-tabling is limited and all games are private. In order to play, players will need a trusted agent and only a few clubs will offer tournaments.",

    offers: [
      "Skrill and Neteller deposits and withdrawals",
      "Several club based applications where players need to join/be assigned to a club to play",
      "Limited promotions",
      "Pretty simple website with little to no information",
      "Customisable such as car deck and bet sizing",
    ],
    note: "",
    link: "https://pppoker.net",
    rating: "3.5",
  },
  {
    name: "Upoker",
    banner: UPokerBanner,
    logo: UPokerLogo,
    description:
      "Upoker belongs to a new generation of poker applications  that are currently in the market. The software evolution have led to quick development of Upoker as a whole. The appearance of the tables at Upoker is very similar to PPPoker, the players have a wide range of customization options including the card deck and bet sizing. The tracking software is not available but players can check basic information about the opponents.",
    offers: [
      "ecoPayz, Neteller, Skrill and Bitcoin deposit and withdrawal options",
      "Mobile app downloads only",
      "Club based applications where players need to join/be assigned to a club to play",
      "Limited promotions",
      "Pretty simple website with little to no information",
      "Customisable such as car deck and bet sizing",
    ],
    note: "",
    link: "http://upoker.net",
    rating: "3.5",
  },
]

export default PokerReview
